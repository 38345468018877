import { Palette, PaletteMode, alpha, darken, lighten } from '@mui/material';

import { hexToRGBA } from '~/utils/colors';

export interface PaletteTypes {
    [key1: string]: {
        [key2: string]: string;
    };
}

const defaultPalette = (mode: PaletteMode): Palette => {
    const darkBase = '#18172B';
    const lightBase = '#FAF8F6';

    const colors = {
        primary: '#FAC965',
        secondary: '#3A295F',
        success: '#92BFB1',
        error: '#D44159',
        warning: '#D44159',
        info: '#16B1FF',
        cta: '#602CE5',
        background: mode === 'light' ? lightBase : darkBase,
        black: '#1A191C',
        white: '#FFFFFF',
        neutral: '#DED7F4',
    };

    const grey = {
        '50': '#fdfdfc',
        '100': lightBase,
        '200': '#f4f0ec',
        '300': '#eae3dc',
        '400': '#dbcfc3',
        '500': '#c3af9b',
        '600': '#ac9074',
        '700': '#957759',
        '800': '#7d644b',
        '900': '#6a5540',
        A100: '#ffffff',
        A200: '#ffffff',
        A400: '#ffffff',
        A700: '#ffffff',
    };

    const shades = {
        '50': '#F9F9F9',
        '100': '#F5F5F5',
        '200': '#EEEEEE',
        '300': '#ADADAD',
        '400': '#969696',
        '500': '#808080',
        '600': '#666666',
        '700': '#4D4D4D',
        '800': '#333333',
        '900': '#1A1A1A',
        A100: '#ffffff',
        A200: '#ffffff',
        A400: '#ffffff',
        A700: '#ffffff',
    };

    return {
        mode,
        tonalOffset: 0.2,
        contrastThreshold: 4.5,
        primary: {
            light: mode === 'light' ? lighten(colors.primary, 0.1) : lighten(colors.primary, 0.1),
            main: mode === 'light' ? colors.primary : colors.primary,
            dark: mode === 'light' ? darken(colors.primary, 0.04) : darken(colors.primary, 0.15),
            contrastText: mode === 'light' ? colors.black : colors.background,
        },
        secondary: {
            light: mode === 'light' ? lighten(colors.secondary, 0.1) : lighten(colors.secondary, 0.1),
            main: mode === 'light' ? colors.secondary : colors.secondary,
            dark: darken(colors.secondary, 0.1),
            contrastText: colors.black,
        },
        success: {
            light: mode === 'light' ? lighten(colors.success, 0.1) : lighten(colors.success, 0.1),
            main: mode === 'light' ? colors.success : colors.success,
            dark: darken(colors.success, 0.1),
            contrastText: '#FFF',
        },
        error: {
            light: mode === 'light' ? lighten(colors.error, 0.1) : lighten(colors.error, 0.1),
            main: mode === 'light' ? colors.error : colors.error,
            dark: darken(colors.error, 0.1),
            contrastText: '#FFF',
        },
        warning: {
            light: mode === 'light' ? lighten(colors.warning, 0.1) : lighten(colors.warning, 0.1),
            main: mode === 'light' ? colors.warning : colors.warning,
            dark: darken(colors.warning, 0.1),
            contrastText: '#FFF',
        },
        info: {
            light: mode === 'light' ? lighten(colors.secondary, 0.1) : lighten(colors.secondary, 0.1),
            main: mode === 'light' ? colors.secondary : colors.secondary,
            dark: darken(colors.secondary, 0.1),
            contrastText: '#FFF',
        },
        cta: {
            light: mode === 'light' ? lighten(colors.cta, 0.1) : lighten(colors.cta, 0.5),
            main: mode === 'light' ? colors.cta : lighten(colors.cta, 0.1),
            dark: darken(colors.cta, 0.2),
            contrastText: '#FFF',
        },
        neutral: {
            light: mode === 'light' ? lighten(colors.neutral, 0.1) : lighten(colors.neutral, 0.1),
            main: mode === 'light' ? colors.neutral : colors.neutral,
            dark: darken(colors.neutral, 0.06),
            contrastText: colors.black,
        },
        grey,
        shades,
        custom: {
            vipps: '#ff5b24',
            facebook: '#1877f2',
            google: '#E22C29',
            feide: '#183385',
        },
        common: {
            black: colors.black,
            white: colors.white,
        },
        text: {
            primary: mode === 'light' ? colors.black : colors.white,
            secondary: colors.black,
            inverted: '#FFF',
            muted: mode === 'light' ? alpha(colors.black, 0.4) : lighten(colors.secondary, 0.6),
            disabled: mode === 'light' ? lighten(colors.black, 0.6) : lighten(colors.white, 0.6),
        },
        divider: mode === 'light' ? grey[100] : grey[100],
        background: {
            paper: mode === 'light' ? colors.white : lighten(colors.background, 0.05),
            lighter: mode === 'light' ? colors.white : lighten(colors.background, 0.1),
            light: mode === 'light' ? grey[50] : lighten(colors.background, 0.05),
            default: colors.background,
            dark: mode === 'light' ? grey[200] : darken(colors.background, 0.1),
            darker: mode === 'light' ? grey[300] : darken(colors.background, 0.2),
        },
        reader: {
            defaultLightFont: colors.black,
            defaultLightBackground: colors.white,
            focusFont: '#343332',
            focusBackground: '#FFFBF2',
            calmFont: '#343332',
            calmBackground: '#EDDBBE',
            defaultDarkFont: colors.white,
            defaultDarkBackground: darkBase,
            quietFont: '#E6E6F2',
            quietBackground: '#39393C',
        },
        highlight: {
            yellow: '#FFF24F',
            green: '#C8FFAE',
            blue: '#AAC9FF',
            terracota: '#FFD392',
            pink: '#FFCDD9',
            teal: '#B3F0FF',
            purple: '#D9C6FF',
        },
        action: {
            active: colors.success,
            hover: hexToRGBA(grey[300], 0.5),
            selected: colors.info,
            disabled: '',
            disabledBackground: '',
            focus: colors.secondary,
            hoverOpacity: 0,
            selectedOpacity: 0,
            disabledOpacity: 0,
            focusOpacity: 0,
            activatedOpacity: 0,
        },
        getContrastText: () => '#fff',
        augmentColor: () => ({
            light: '',
            main: '',
            dark: '',
            contrastText: '',
        }),
    };
};

export default defaultPalette;
