import { IconButton as MuiIconButton, styled } from '@mui/material';

import IconButtonProps from '../types';

const MenuStyled = styled(MuiIconButton)<IconButtonProps>(({ theme, disabled }) => ({
    borderRadius: 6,
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    padding: '0',
    height: theme.spacing(10),
    width: theme.spacing(10),
    opacity: disabled ? '0.5' : '1',

    '&.MuiButton-sizeSmall': {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },

    '&.MuiButton-sizeLarge': {
        height: theme.spacing(12),
        width: theme.spacing(12),
    },
    '&:hover, &.Mui-hoverVisible': {
        color: theme.palette.primary.main,
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.background.lighter,
    },
    '&.Mui-activeVisible': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[2],
    },
    '&.Mui-focusVisible': {
        boxShadow: theme.shadows[24],
    },
}));

export default MenuStyled;
